import React from "react";
import "../../assets/css/stylesTenant.css";
import zalo from "../../assets/images/zalo.png";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteTenant,
  fetchControl,
  saveTenant,
} from "../../services/tenantServices";
import { Navigate } from "react-router-dom";
import Configuration from "../configuration/configuration";

class Tenant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTenant: "",
      listTenant: [],
      listContact: [],
      listRegion: [],
      listIncoming: [],
      listGoogleLanguage: [],
      listMessagingType: [],
       //thong tin system config
      cfgDomainName:"",
      cfgIsSendLogsSlack:"", 
      cfgSlackChannel:"",

      id: uuidv4(),
      tenantName: "",
      tenantIdentifier: "",
      apiToken: "",

      contactType: "",
      region: "",
      organization: "",
      clientIdentifier: "",
      secret: "",
      inboundMessageFlow: "",
      openMessagingName: "",
      openMessagingID: "",
      openMessagingWebhook: "https://gum-api.nextel.com.vn/api/v1/genconnector/genesys",
      openMessagingToken: "",
      incomingMessageFlow: "",
      openMessagingMetadata: "",
      commander: false,
      googleMapsKey: "",
      googleMapsSecret: "",
      googleMapsLanguage: "",
      googleMapsHeight: 0,
      googleMapsWidth: 0,
      googleMapsZoomLevel: 0,

      messagingType: "",
      applicationIdentifier: "",
      applicationSecretKey: "",
      oaIdentifier: "",
      oaSecretKey: "",
      domainVerificationTag: "",
      accessToken: "",
      refreshToken: "",
      webhook: "https://gum-api.nextel.com.vn/api/v1/genconnector/zalo",
      oaRedirectURL: "",

      showSystemMessages: true,
      displayAgentName: true,

      urlSlackWebhook: "https://api.slack.com/messaging/webhooks",
      isSendLogsSlack: true,
      slackWebhook: "",

      officialAccount: "",

      isGenIdentifier: false,
      isShowNotiAccessToken: false,
      isLogin: sessionStorage.getItem("isLogin"),

      Key1:"",
      Value1:"",
      Key2:"",
      Value2:"",
      Key3:"",
      Value3:"",
      Key4:"",
      Value4:"",
      Key5:"",
      Value5:"",
    };
  }

  async componentDidMount() {
    // lấy dữ liệu khởi tạo tenant
    let res = await fetchControl();

    if (res.status === 200) {
     console.log(res.data.cfgDomainName);
      this.setState({
        listTenant:
          res && res.data && res.data.dataTenant ? res.data.dataTenant : [],
        listContact:
          res && res.data && res.data.dataContact ? res.data.dataContact : [],
        listRegion:
          res && res.data && res.data.dataRegion ? res.data.dataRegion : [],
        listIncoming:
          res && res.data && res.data.dataIncoming ? res.data.dataIncoming : [],
        listGoogleLanguage:
          res && res.data && res.data.dataGoogleLanguage
            ? res.data.dataGoogleLanguage
            : [],
        listMessagingType:
          res && res.data && res.data.dataMessagingType
            ? res.data.dataMessagingType
            : [],
        cfgDomainName: res.data.cfgDomainName,
        cfgIsSendLogsSlack : res.data.cfgIsSendLogsSlack,
        cfgSlackChannel : res.data.cfgSlackChannel,
      });
      console.log(this.state.cfgDomainName);
      //alert(cfgDomainName);
      sessionStorage.getItem("isLogin");
    }
  }

  // tạo mới tenant (renew dữ liệu)
  onClickNewTenant = () => {

    this.setState({
      selectedTenant: "",
      tenantName: "",
      tenantIdentifier: "",
      apiToken: "",

      id: uuidv4(),
      contactType: "",
      region: "",
      organization: "",
      clientIdentifier: "",
      secret: "",
      inboundMessageFlow: "",
      openMessagingName: "",
      openMessagingID: "",
      openMessagingWebhook: "",
      openMessagingToken: "",
      incomingMessageFlow: "",
      openMessagingMetadata: "",
      commander: false,
      googleMapsKey: "",
      googleMapsSecret: "",
      googleMapsLanguage: "",
      googleMapsHeight: 0,
      googleMapsWidth: 0,
      googleMapsZoomLevel: 0,

      messagingType: "",
      applicationIdentifier: "",
      applicationSecretKey: "",
      oaIdentifier: "",
      oaSecretKey: "",
      domainVerificationTag: "",
      accessToken: "",
      refreshToken: "",
      webhook: "",
      oaRedirectURL: "",

      showSystemMessages: true,
      displayAgentName: true,
      isSendLogsSlack: true,
      slackWebhook: "",
      officialAccount: "",

      isGenIdentifier: false,
      isShowNotiAccessToken: false,

      Key1:"",
      Value1:"",
      Key2:"",
      Value2:"",
      Key3:"",
      Value3:"",
      Key4:"",
      Value4:"",
      Key5:"",
      Value5:"",
    });
  };

  // hàm chọn tenant thao tác
  onChangeTenantHandler = (e) => {
    let listFilter = this.state.listTenant.filter((tenant) => {
      return tenant.tenantIdentifier === e.target.value;
    });

    let ten = listFilter[0];
    console.log(this.cfgDomainName);
    this.setState({
      isGenIdentifier: true,
      selectedTenant: e.target.value,
      tenantName: ten.tenantName === null ? "" : ten.tenantName,
      tenantIdentifier: ten.tenantIdentifier,
      apiToken: ten.apiToken === null ? "" : ten.apiToken,
      contactType: ten.contactType === null ? "" : ten.contactType,
      region: ten.region === null ? "" : ten.region,
      organization: ten.organization === null ? "" : ten.organization,
      clientIdentifier:
        ten.cxClientIdentifier === null ? "" : ten.cxClientIdentifier,
      secret: ten.cxClientSecret === null ? "" : ten.cxClientSecret,
      inboundMessageFlow:
        ten.inboundMessageFlow === null ? "" : ten.inboundMessageFlow,
      openMessagingName:
        ten.openMsgPlatformName === null ? "" : ten.openMsgPlatformName,
      openMessagingID:
        ten.openMsgPlatformId === null ? "" : ten.openMsgPlatformId,
      openMessagingWebhook: this.state.cfgDomainName + "/api/v1/genconnector/genesys",
        //ten.openMsgPlatformWebHook === null
          //? "http://afit.io.vn/api/v1/genconnector/genesys"
          //: ten.openMsgPlatformWebHook,
      openMessagingToken:
        ten.openMsgPlatformToken === null ? "" : ten.openMsgPlatformToken,
      incomingMessageFlow:
        ten.incomingMessageFlow === null ? "" : ten.incomingMessageFlow,
      openMessagingMetadata: "",
      commander: ten.commander === null ? false : ten.commander,
      googleMapsKey: ten.googleMapKey === null ? "" : ten.googleMapKey,
      googleMapsSecret: ten.googleMapSecret === null ? "" : ten.googleMapSecret,
      googleMapsLanguage:
        ten.googleMapLanguage === null ? "" : ten.googleMapLanguage,
      googleMapsHeight: ten.googleMapHeight === null ? 0 : ten.googleMapHeight,
      googleMapsWidth: ten.googleMapWidth === null ? 0 : ten.googleMapWidth,
      googleMapsZoomLevel:
        ten.googleMapZoomLevel === null ? 0 : ten.googleMapZoomLevel,
      messagingType: ten.messagingType === null ? "" : ten.messagingType,
      applicationIdentifier:
        ten.zaloApplicationId === null ? "" : ten.zaloApplicationId,
      applicationSecretKey:
        ten.zaloAppicationSecretKey === null ? "" : ten.zaloAppicationSecretKey,
      oaIdentifier: ten.oaIdentifier === null ? "" : ten.oaIdentifier,
      oaSecretKey: ten.oaSecretKey === null ? "" : ten.oaSecretKey,
      domainVerificationTag:
        ten.domainVerificationTag === null ? "" : ten.domainVerificationTag,
      accessToken: ten.accessToken === null ? "" : ten.accessToken,
      refreshToken: ten.refreshToken === null ? "" : ten.refreshToken,
      showSystemMessages:
        ten.showSystemMessages === null
          ? false
          : ten.showSystemMessages === 0
          ? false
          : true,
      displayAgentName:
        ten.displayAgentName === null
          ? false
          : ten.displayAgentName === 0
          ? false
          : true,
      webhook: this.state.cfgDomainName + "/api/v1/genconnector/zalo",
        //ten.webHook === null
        //  ? "https://afit.io.vn/api/v1/genconnector/zalo"
        //  : ten.webHook,
      officialAccount:
        ten.zaloApplicationId === ""
          ? ""
          : this.state.cfgDomainName + "/api/v1/config/svzauth" + "?app_id=" + ten.zaloApplicationId,
          //: ten.OACallBackUrl + "?app_id=" + ten.zaloApplicationId,
      oaRedirectURL:
        "https://oauth.zaloapp.com/v4/oa/permission?app_id=" +
        ten.zaloApplicationId +
        "&redirect_uri=" +
        encodeURIComponent(
          this.state.cfgDomainName + "/api/v1/config/svzauth" + "?app_id=" + ten.zaloApplicationId
         // ten.OACallBackUrl + "?app_id=" + ten.zaloApplicationId
        ),
      isSendLogsSlack:
        ten.isSendLogsSlack === null
          ? false
          : ten.isSendLogsSlack === 0
          ? false
          : true,
      slackWebhook: ten.slackWebhook === null ? "" : ten.slackWebhook,
      isShowNotiAccessToken: ten.zaloApplicationId === "" ? false : true,
      
      Key1: ten.Key1 === null? "": ten.Key1,
      Value1: ten.Value1 === null? "": ten.Value1,
      Key2: ten.Key2 === null? "": ten.Key2,
      Value2: ten.Value2 === null? "": ten.Value2,
      Key3: ten.Key3 === null? "": ten.Key3,
      Value3: ten.Value3 === null? "": ten.Value3,
      Key4: ten.Key4 === null? "": ten.Key4,
      Value4: ten.Value4 === null? "": ten.Value4,
      Key5: ten.Key5 === null? "": ten.Key5,
      Value5: ten.Value5 === null? "": ten.Value5,
    });
  };

  // hàm set value
  onChangeHandle = (e) => {
    let value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  };

  // hàm gen guid id
  genGuidIdentifier = () => {
    this.setState({
      tenantIdentifier: uuidv4(),
    });
  };

  // hàm gen guid id
  genGuidAPIToken = () => {
    this.setState({
      apiToken: uuidv4(),
    });
  };

  // hàm save and update
  onClickSaveTenant = async () => {
    if (
      this.state.tenantIdentifier === "" ||
      this.state.tenantIdentifier === null ||
      this.state.tenantIdentifier === undefined
    )
      this.toastSvc(
        "warning",
        "Cannot create tenant because 'Tenant Identifier is empty'"
      );
    else {      
      let res = await saveTenant(this.state);
      if (res.status === 200) {
        if (res.data.isSuccess) {
          this.toastSvc("success", res.data.message);
          this.onClickNewTenant();
          this.componentDidMount();
        } else this.toastSvc("error", res.data.message);
      }
    }
  };

  // hàm xóa tenant
  onClickDeletedTenant = async () => {
    if (
      this.state.selectedTenant === "" ||
      this.state.selectedTenant === undefined
    ) {
      this.toastSvc("warning", "Please choose one tenant");
      return;
    }

    if (window.confirm("Delete the tenant ?")) {
      let res = await deleteTenant(this.state.selectedTenant);
      if (res.status === 200) {
        if (res.data.isSuccess) {
          this.toastSvc("success", res.data.message);
          this.onClickNewTenant();
          this.componentDidMount();
        } else this.toastSvc("error", res.data.message);
      }
    }
  };

  onClickCtCPlatformWebhook = (e) => {
    try {
      navigator.clipboard.writeText(this.state.openMessagingWebhook);
      this.toastSvc("success", "Copy to clipboard success");
    } catch (e) {
      window.prompt(
        "Copy to clipboard: Ctrl+C, Enter",
        this.state.openMessagingWebhook
      );
    }
  };

  onClickCtCWebhook = (e) => {
    try {
      navigator.clipboard.writeText(this.state.webhook);
      this.toastSvc("success", "Copy to clipboard success");
    } catch (e) {
      window.prompt("Copy to clipboard: Ctrl+C, Enter", this.state.webhook);
    }
  };

  onClickCtCOfficialAccount = (e) => {
    try {
      navigator.clipboard.writeText(this.state.officialAccount);
      this.toastSvc("success", "Copy to clipboard success");
    } catch (e) {
      window.prompt(
        "Copy to clipboard: Ctrl+C, Enter",
        this.state.officialAccount
      );
    }
  };

  onChangeHandleCheckbox = (e) => {
    const { checked } = e.target;
    this.setState({
      [e.target.name]: checked,
    });
  };

  toastSvc(type, content) {
    switch (type) {
      case "success":
        toast.success(content, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        break;
      case "warning":
        toast.warn(content, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        break;
      case "error":
        toast.error(content, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        break;
    }
  }

  // cho nhập số không cho nhập text
  numberOnly = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  render() {
    return (
      <>
        <div>{!this.state.isLogin && <Navigate to="/" replace />}</div>
        <div className="header">
          <div className="row ">
            <div className="col-sm-3 col-md-3 col-lg-3">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <label className="header__label">Tenant</label>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 col-lg-5">
              <div className="col-sm-10 col-md-10 col-lg-10">
                <select
                  className="custom-select"
                  value={this.state.selectedTenant}
                  onChange={(event) => this.onChangeTenantHandler(event)}
                >
                  <option value=""> Please choose one option </option>
                  {this.state.listTenant.map((option, index) => {
                    return (
                      <option key={index} value={option.tenantIdentifier}>
                        {option.tenantName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="header__button">                  
                   <Configuration/>
                  &nbsp;
                  <button
                    className="btn btn-secondary"
                    onClick={() => this.onClickNewTenant()}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                  &nbsp;
                  <button className="btn btn-secondary">
                    <i className="fas fa-upload"></i>
                  </button>
                  &nbsp;
                  <button className="btn btn-secondary">
                    <i className="fas fa-download"></i>
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-dark"
                    onClick={() => this.onClickSaveTenant()}
                  >
                    <i className="fas fa-save"></i>
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-danger"
                    onClick={() => this.onClickDeletedTenant()}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="general-tab"
                data-toggle="tab"
                href="#general"
                role="tab"
                aria-controls="general"
                aria-selected="false"
              >
                General
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link "
                id="platform-tab"
                data-toggle="tab"
                href="#platform"
                role="tab"
                aria-controls="platform"
                aria-selected="true"
              >
                CX Platform
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link "
                id="messaging-tab"
                data-toggle="tab"
                href="#messaging"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Messaging
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="general"
              role="tabpanel"
              aria-labelledby="general-tab"
            >
              <div className="content__general">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Tenant Name</label>
                    <input
                      type="text"
                      name="tenantName"
                      className="form-control"
                      value={this.state.tenantName}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Tenant Identifier</label>
                    <div className="input-group mb-12">
                      <input
                        type="text"
                        name="tenantIdentifier"
                        className="form-control"
                        value={this.state.tenantIdentifier}
                        onChange={() => {}}
                      />
                      <div className="input-group-append">
                        <button
                          onClick={() => this.genGuidIdentifier()}
                          disabled={this.state.isGenIdentifier}
                          className="btn btn-outline-secondary"
                          type="button"
                        >
                          <i className="fa-solid fa-copy"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="general__lb_api">API Token</label>
                    <div className="input-group mb-12">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.apiToken}
                        onChange={() => {}}
                      />
                      <div className="input-group-append">
                        <button
                          onClick={() => this.genGuidAPIToken()}
                          className="btn btn-outline-secondary"
                          type="button"
                        >
                          <i className="fa-solid fa-copy"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Options:</label>
                    <br />
                    <input
                      id="id_send_logs_slack"
                      name="isSendLogsSlack"
                      type="checkbox"
                      checked={this.state.isSendLogsSlack}
                      onChange={(e) => this.onChangeHandleCheckbox(e)}
                    ></input>
                    &nbsp;
                    <label htmlFor="id_send_logs_slack">
                      Send logs to Slack
                    </label>
                    <br />
                    <label>
                      Slack webhook &nbsp;
                      <a href={this.state.urlSlackWebhook}>
                        ({this.state.urlSlackWebhook})
                      </a>
                    </label>
                    <br />
                    <input
                      type="text"
                      className="form-control"
                      name="slackWebhook"
                      value={this.state.slackWebhook}
                      onChange={(e) => this.onChangeHandle(e)}
                    ></input>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="platform"
              role="tabpanel"
              aria-labelledby="platform-tab"
            >
              <div className="content__platform">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Contact Type:</label>
                    <select
                      className="custom-select"
                      value={this.state.contactType}
                      name="contactType"
                      onChange={(e) => this.onChangeHandle(e)}
                    >
                      <option> Please choose one option </option>
                      {this.state.listContact.map((option, index) => {
                        return (
                          <option key={index} value={option.contactCode}>
                            {option.contactName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="platform__lb_cloud">
                      Genesys Cloud OAuth Integration
                    </label>
                    <div className="platform__div_discription">
                      <p className="platform__p_discription">
                        To connect to Genesys Cloud CX, you will need
                        credentials form an OAUTH Integration wiht 'Client
                        Credentials' grant type. The mandatory permissions are:
                      </p>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Region</label>
                    <select
                      className="custom-select"
                      value={this.state.region}
                      name="region"
                      onChange={(e) => this.onChangeHandle(e)}
                    >
                      <option> Please choose one option </option>
                      {this.state.listRegion.map((option, index) => {
                        return (
                          <option key={index} value={option.regionCode}>
                            {option.regionName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Organization</label>
                    <div className="input-group mb-12">
                      <input
                        type="text"
                        name="organization"
                        className="form-control"
                        value={this.state.organization}
                        onChange={(e) => this.onChangeHandle(e)}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                        >
                          <i className="fa-solid fa-copy"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Client Identifier</label>
                    <input
                      type="text"
                      name="clientIdentifier"
                      className="form-control"
                      value={this.state.clientIdentifier}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Secret</label>
                    <input
                      type="text"
                      name="secret"
                      className="form-control"
                      value={this.state.secret}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="platform__lb_cloud">
                      Genesys Cloud Open Messaging
                    </label>
                    <label>Inbound Message Flow (sample)</label>
                    <div className="platform__div_discription">
                      <p className="platform__p_discription">
                        You can download this sample flow to get started with
                        Genesys Cloud CX. It will route incoming conversation to
                        agents. You will need to point the ACD Transfers to
                        appropriate queue
                      </p>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <input
                      type="text"
                      className="form-control"
                      name="inboundMessageFlow"
                      value={this.state.inboundMessageFlow}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Open Messaging Platform Name</label>
                    <input
                      type="text"
                      name="openMessagingName"
                      className="form-control"
                      value={this.state.openMessagingName}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Open Messaging Platform ID</label>
                    <input
                      type="text"
                      name="openMessagingID"
                      className="form-control"
                      value={this.state.openMessagingID}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Open Messaging Platform Webhook</label>
                    <div className="input-group mb-12">
                      <input
                        disabled
                        type="text"
                        name="openMessagingWebhook"
                        value={this.state.openMessagingWebhook}
                        className="form-control"
                        onChange={(e) => this.onChangeHandle(e)}
                      />
                      <div className="input-group-append">
                        <button
                          onClick={() => this.onClickCtCPlatformWebhook()}
                          className="btn btn-outline-secondary"
                          type="button"
                        >
                          <i className="fa-solid fa-copy"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Open Messaging Platform Token</label>
                    <div className="input-group mb-12">
                      <input
                        type="text"
                        className="form-control"
                        name="openMessagingToken"
                        value={this.state.openMessagingToken}
                        onChange={(e) => this.onChangeHandle(e)}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                        >
                          <i className="fa-solid fa-rotate"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Incoming Messsage Flow</label>
                    <select
                      className="custom-select"
                      name="incomingMessageFlow"
                      value={this.state.incomingMessageFlow}
                      onChange={(e) => this.onChangeHandle(e)}
                    >
                      <option value="">Please choose one option</option>
                      {this.state.listIncoming.map((option, index) => {
                        return (
                          <option key={index} value={option.incomingCode}>
                            {option.incomingName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Key1</label>
                    <input
                      type="text"
                      name="Key1"
                      className="form-control"
                      value={this.state.Key1}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Value1</label>
                    <input
                      type="text"
                      name="Value1"
                      className="form-control"
                      value={this.state.Value1}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>                 
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Key2</label>
                    <input
                      type="text"
                      name="Key2"
                      className="form-control"
                      value={this.state.Key2}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Value2</label>
                    <input
                      type="text"
                      name="Value2"
                      className="form-control"
                      value={this.state.Value2}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>                 
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Key3</label>
                    <input
                      type="text"
                      name="Key3"
                      className="form-control"
                      value={this.state.Key3}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Value3</label>
                    <input
                      type="text"
                      name="Value3"
                      className="form-control"
                      value={this.state.Value3}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>                 
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Key4</label>
                    <input
                      type="text"
                      name="Key4"
                      className="form-control"
                      value={this.state.Key4}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Value4</label>
                    <input
                      type="text"
                      name="Value4"
                      className="form-control"
                      value={this.state.Value4}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>                 
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Key5</label>
                    <input
                      type="text"
                      name="Key5"
                      className="form-control"
                      value={this.state.Key5}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3">
                    <label>Value5</label>
                    <input
                      type="text"
                      name="Value5"
                      className="form-control"
                      value={this.state.Value5}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>                 
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="platform__lb_cloud">Commander</label>
                    <input
                      type="checkbox"
                      name="commander"
                      id="use_commander"
                      onChange={(e) => this.onChangeHandle(e)}
                    />{" "}
                    &nbsp;
                    <label htmlFor="use_commander">User Commander</label>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="platform__lb_cloud">Google Maps</label>
                    <div className="platform__div_discription">
                      <p className="platform__p_discription">
                        Google Maps: These values can be set to display a
                        preview of Locations on the agent's desk. To obtain the
                        key and the secret, see: <a>Google Maps Platform</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Google Maps Key *</label>
                    <input
                      type="text"
                      name="googleMapsKey"
                      className="form-control"
                      value={this.state.googleMapsKey}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Google Maps Secret *</label>
                    <input
                      type="text"
                      name="googleMapsSecret"
                      className="form-control"
                      value={this.state.googleMapsSecret}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Google Maps Language *</label>
                    <select
                      className="custom-select"
                      name="googleMapsLanguage"
                      value={this.state.googleMapsLanguage}
                      onChange={(e) => this.onChangeHandle(e)}
                    >
                      <option value="">Please choose one option</option>
                      {this.state.listGoogleLanguage.map((option, index) => {
                        return (
                          <option key={index} value={option.languageCode}>
                            {option.languageName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Google Maps Height *</label>
                    <div className="input-group mb-12">
                      <input
                        type="text"
                        className="form-control"
                        name="googleMapsHeight"
                        value={this.state.googleMapsHeight}
                        onKeyPress={(e) => this.numberOnly(e)}
                        onChange={(e) => this.onChangeHandle(e)}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          pixels
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Google Maps Width *</label>
                    <div className="input-group mb-12">
                      <input
                        type="text"
                        className="form-control"
                        name="googleMapsWidth"
                        value={this.state.googleMapsWidth}
                        onKeyPress={(e) => this.numberOnly(e)}
                        onChange={(e) => this.onChangeHandle(e)}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          pixels
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Google Maps Zoom Level *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="googleMapsZoomLevel"
                      value={this.state.googleMapsZoomLevel}
                      onKeyPress={(e) => this.numberOnly(e)}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane fade "
              id="messaging"
              role="tabpanel"
              aria-labelledby="messaging-tab"
            >
              <div className="content__messaging">
                <div className="row">
                  <div className="col-sm-11 col-md-11 col-lg-11">
                    <label>Messaging Type</label>
                    <select
                      className="custom-select"
                      name="messagingType"
                      value={this.state.messagingType}
                      onChange={(e) => this.onChangeHandle(e)}
                    >
                      <option value="">Please choose one option</option>
                      {this.state.listMessagingType.map((option, index) => {
                        return (
                          <option key={index} value={option.messagingCode}>
                            {option.messagingName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-1 col-md-1 col-lg-1">
                    <div className="messaging__img_zalo">
                      <img src={zalo} />
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>
                      Application Identifier &emsp;
                      <span className="note__span">
                        (Enter the App ID from the Zalo Official Account
                        console)
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="applicationIdentifier"
                      value={this.state.applicationIdentifier}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>
                      Application Secret Key &emsp;
                      <span className="note__span">
                        (Enter the App Secret from the Zalo Official Account
                        console)
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="applicationSecretKey"
                      value={this.state.applicationSecretKey}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"  ></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>
                      Official Account (OA) Identifier &emsp;
                      <span className="note__span">
                        (Enter the Official Account ID from the Zalo Official
                        Account console)
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="oaIdentifier"
                      value={this.state.oaIdentifier}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>
                      Official Account (OA) Secret Key &emsp;
                      <span className="note__span">
                        (Enter the OA Secret Key from the Zalo Official Account
                        console)
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="oaSecretKey"
                      value={this.state.oaSecretKey}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>
                      Domain Verification Meta Tag &emsp;
                      <span className="note__span">
                        (Enter the Domain Verification meta tab value from the
                        Zalo Official Account console)
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="domainVerificationTag"
                      value={this.state.domainVerificationTag}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>
                      Official Account Callback Url &emsp;
                      <span className="note__span">
                        (Copy the link to "Official Account Callback Url" in
                        Zalo configuration page)
                      </span>
                    </label>
                    <div className="input-group mb-12">
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        name="officialAccount"
                        value={this.state.officialAccount}
                        onChange={(e) => this.onChangeHandle(e)}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => this.onClickCtCOfficialAccount()}
                        >
                          <i className="fa-solid fa-copy"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Access Token</label>
                    <div
                      className="platform__div_noti"
                      hidden={this.state.isShowNotiAccessToken}
                    >
                      <p className="platform__p_noti">
                        You need to save the Tenant with Zalo Application Id to
                        be able to request the Tokens from Zalo.
                      </p>
                    </div>
                    <div
                      className="platform__div_discription"
                      hidden={!this.state.isShowNotiAccessToken}
                    >
                      <p className="platform__p_discription">
                        Click here to get Zalo Token:
                        <br />
                        <a href={this.state.oaRedirectURL}>
                          {this.state.oaRedirectURL}
                        </a>
                      </p>
                    </div>
                    <div className="content__div_box"></div>
                    <input
                      type="text"
                      className="form-control"
                      name="accessToken"
                      value={this.state.accessToken}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Refresh Token</label>
                    <input
                      type="text"
                      className="form-control"
                      name="refreshToken"
                      value={this.state.refreshToken}
                      onChange={(e) => this.onChangeHandle(e)}
                    />
                  </div>
                </div>
                <div className="content__div_box"></div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label>Webhook</label>
                    <div className="platform__div_discription">
                      <p className="platform__p_discription">
                        <a>
                          Zalo Webhook Settings (enter the Application
                          Identifier to be able to use this link)
                        </a>
                        <br />
                        On the Zalo Offical Account consolem, use the following
                        Webhook URL and request the following events:
                        user_received_message, user_send_audio, user_send_file,
                        user_send_gif, user_send_image, users_send_link,
                        user_send_location, user_send_sticker, user_send_text,
                        user_send_video and user_submit_info
                      </p>
                    </div>
                    <div className="content__div_box"></div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="input-group mb-12">
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            name="webhook"
                            value={this.state.webhook}
                            onChange={(e) => this.onChangeHandle(e)}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() => this.onClickCtCWebhook()}
                            >
                              <i className="fa-solid fa-copy"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content__div_box"></div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <label>Options:</label>
                        <br />
                        <input
                          id="id_show_system_messages"
                          name="showSystemMessages"
                          type="checkbox"
                          checked={this.state.showSystemMessages}
                          onChange={(e) => this.onChangeHandleCheckbox(e)}
                        ></input>{" "}
                        &nbsp;
                        <label htmlFor="id_show_system_messages">
                          Show System Messages
                        </label>
                        <br />
                        <input
                          id="id_display_agent_name"
                          name="displayAgentName"
                          type="checkbox"
                          checked={this.state.displayAgentName}
                          onChange={(e) => this.onChangeHandleCheckbox(e)}
                        ></input>{" "}
                        &nbsp;
                        <label htmlFor="id_display_agent_name">
                          Display Agent Name
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    );
  }
}

export default Tenant;
