import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route,Navigate  } from 'react-router-dom';
import Tenant from './tenant/tenant';
import Login from './login/login';

function App() {
 
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<Login/>}></Route>
          <Route
            path='/tenant'
            element={<Tenant/>}
          />
        </Routes>
      </div>
    </Router>
  );
}
export default App;
