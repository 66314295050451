import React from "react";
import "../../assets/css/stylesLogin.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import { login } from "../../services/loginServices";
class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            passWord: "",
            isLogin: sessionStorage.getItem("isLogin")
        };
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onClickLogin = async () => {
        if (this.state.userName === "" || this.state.userName.length === 0) {
            this.toastSvc("warning", "Username is required");
            return;
        }
        if (this.state.passWord === "" || this.state.userName.lenpassWordgth === 0) {
            this.toastSvc("warning", "Password is required");
            return;
        }

        let res = await login(this.state);
           
        if (res.status === 200) {
            if (res.data.token != null) {
                localStorage.setItem('accessToken',res.data.token);
                this.toastSvc("success", res.data.message);
                sessionStorage.setItem("isLogin",true);
                this.setState({ isLogin: true })
            } else {
                this.toastSvc("error", res.data.message);
                this.setState({
                    userName: "",
                    passWord: ""
                })
            }

        }
    }

    toastSvc(type, content) {
        switch (type) {
            case "success":
                toast.success(content, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                break;
            case "warning":
                toast.warn(content, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                break;
            case "error":
                toast.error(content, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                break;
        }
    }

    render() {
        return (
            <>
                <div>{
                    this.state.isLogin && (<Navigate to="/tenant" replace={true} />)
                }
                </div>
                <div className="page">
                    <div className="cover">
                        <div className="container">
                            <div className="row ">
                                <div className="col-md-12">
                                    <h1>LOGIN</h1>
                                </div>
                                <div className="col-md-12 login__space_item">
                                    <h6>Please enter your login and password</h6>
                                </div>

                                <div className="col-md-12 login__space_item">
                                    <input type="text" className="form-control" name="userName" value={this.state.userName} onChange={(e) => this.onChangeHandler(e)} placeholder="Username"></input>
                                </div>
                                <div className="col-md-12 login__space_item">
                                    <input type="password" className="form-control" name="passWord" value={this.state.passWord} onChange={(e) => this.onChangeHandler(e)} placeholder="Password"></input>
                                </div>
                                <div className="col-md-12 login__space_item">
                                    <div className="col-md-12">
                                        <button className="btn btn-primary" onClick={() => this.onClickLogin()} >Login</button> &emsp;&emsp;
                                        <button className="btn btn-dark">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </>

        )
    }
}

export default Login