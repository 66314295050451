import axios from "./axiosServices";

const fetchControl = () => {
  return axios.get("/api/v1/getTenant");
};

const saveTenant = (data) => {
  return axios.post("/api/v1/saveTenant", data);
};

const deleteTenant = (tenantIdentifier) => {
    return axios.post("/api/v1/deleteTenant", {
        tenantIdentifier: tenantIdentifier
    });
};

export { fetchControl, saveTenant,deleteTenant };
