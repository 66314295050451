import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getConfig, saveConfig } from "../../services/configurationServices";

function Configuration() {
  const [show, setShow] = useState(false);
  //const [domainName, setDomainName] = useState("");
  const [isSendLogSlack, setIsSendLogSlack] = useState(true);
  const [slackChanel, setSlackChanel] = useState("");

  const [urlSlackWebhook, setUrlSlackWebhook] = useState(
    "https://api.slack.com/messaging/webhooks"
  );

  const [listConfig, setListConfig] = useState({
    domainName: "",
    isSendLogSlack: true,
    slackChanel: "",
  });

  useEffect(() => {
    getAllConfiguration();
  }, []);

  const getAllConfiguration = async () => {
    let res = await getConfig();
    if (res.status === 200)
      if (res && res.data && res.data.dataConfig) {
        let data = res.data.dataConfig;
        let filterDomain = data.filter((value) => {
          return value.configName === "domainName";
        });

        let filterIsSend = data.filter((value) => {
          return value.configName === "isSendLogToSlack";
        });

        let filterSlackChanel = data.filter((value) => {
          return value.configName === "slackChannel";
        });

        setListConfig((prevState) => ({
          ...prevState,
          domainName: filterDomain[0].configValue,
        }));
        setListConfig((prevState) => ({
          ...prevState,
          isSendLogSlack: filterIsSend[0].configValue,
        }));
        setListConfig((prevState) => ({
          ...prevState,
          slackChanel: filterSlackChanel[0].configValue,
        }));
      }
  };

  const saveConfiguration = async () => {
    if (listConfig.domainName === "" || listConfig.slackChanel === "")
      toast.error("Cannot save config because 'data is empty'", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    else {
      let res = await saveConfig(listConfig);
      if (res.status === 200) {
        if (res.data.isSuccess) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          handleClose();
        } else
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      }
    }
  };

  const onChangeHandleInput = (e) => {
    const { name, value } = e.target;
    setListConfig((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeHandleCheckbox = (e) => {
    const { name, checked } = e.target;
    setListConfig((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        <i className="fas fa-gear"></i>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>SYSTEM CONFIGURATION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="configuration">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <label>Domain Name</label>
                <input
                  type="text"
                  name="domainName"
                  className="form-control"
                  value={listConfig.domainName}
                  onChange={(e) => onChangeHandleInput(e)}
                />
              </div>
            </div>
            <div className="content__div_box"></div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <input
                  id="id_send_log_slack"
                  name="isSendLogSlack"
                  type="checkbox"
                  checked={listConfig.isSendLogSlack}
                  onChange={(e) => onChangeHandleCheckbox(e)}
                ></input>
                &nbsp;
                <label htmlFor="id_send_log_slack">Send logs to Slack</label>
                <br />
                <label>
                  Slack webhook &nbsp;
                  <a href={urlSlackWebhook}>({urlSlackWebhook})</a>
                </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  name="slackChanel"
                  value={listConfig.slackChanel}
                  onChange={(e) => onChangeHandleInput(e)}
                ></input>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveConfiguration}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Configuration;
