import axios from "./axiosServices";

const getConfig = () => {
  return axios.get("/api/v1/getConfig");
};

const saveConfig = (data) =>{
  return axios.post("/api/v1/saveConfig",data);
}


export { getConfig, saveConfig };
