import axios from "./axiosServices";

const login = (data) => {
  return axios.post("/api/v1/login", data);
};
const getProfileFetch = async () => {
  try {
    const response = await axios.get('/api/v1/profile');
    return response.data;
  } 
  catch (error) {
    throw error;
  }
};
export { login, getProfileFetch};
